<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1>Dettagli coltura {{cropOldDetails.name}}</h1>
    <div class="">
      <div class="my-5">
        <img
            :src="cropDetails.ico_path"
            :alt="cropDetails.name"
            class="inline-block h-20 w-20 rounded-3xl m-auto border border-black shadow"
        />
      </div>

      <div>
        <h2>Imposta i nuovi dettagli per la coltura selezionata</h2>
      </div>

      <div class="sm:px-6 lg:px-8">
        <div class="pt-4 sm:pt-10 sm:space-y-5">
          <h3 class="block text-sm font-medium text-gray-700">Nuovo nome coltura:</h3>
          <p>{{cropDetails.name}}</p>
        </div>
        <div class="pt-4 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <label for="group" class="block text-sm font-medium text-gray-700">Immagine</label>
            <select
                v-model="cropDetails.ico_path"
                id="cropImage"
                name="cropImage"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option
                  @click="editName(image)"
                  v-for="image in cropsImage"
                  :key="image.label"
                  :value="image.path"
              >{{image.label}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button @click="router.push('/colture-gestione/')" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Annulla</button>
          <button
              @click.prevent="updateCrop"
              type="submit"
              class="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Salva</button>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  useRoute,
  useRouter,
} from 'vue-router'
import axios from 'axios'


const route = ref(useRoute())
const router = ref(useRouter())
const cropId = ref("");
const cropDetails = ref("");
const cropOldDetails = ref("");

const cropsImage = ref([
  {label: 'Bieta',path: '/images/crops/bieta.png'},
  {label: 'Carota',path: '/images/crops/carota.png'},
  {label: 'Cavolfiore',path: '/images/crops/cavolfiore.png'},
  {label: 'Cavolo',path: '/images/crops/cavolo.png'},
  {label: 'Cetriolo',path: '/images/crops/cetriolo.png'},
  {label: 'Cipolla',path: '/images/crops/cipolla.png'},
  {label: 'Finocchio',path: '/images/crops/finocchio.png'},
  {label: 'Melanzana',path: '/images/crops/melanzana.png'},
  {label: 'Patata',path: '/images/crops/patata.png'},
  {label: 'Peperone',path: '/images/crops/peperone.png'},
  {label: 'Pomodoro',path: '/images/crops/pomodoro.png'},
  {label: 'Radicchio',path: '/images/crops/radicchio.png'},
])

const updateCrop = async () => {

  await axios.put('/api/crop/'+cropId.value, cropDetails.value)
      .then( async () => {
        await loadCrop();
        alert('Le modifiche alla coltura sono state salvate.')
      })
      .catch( error => {
        console.debug(error.response)
      });
}

const editName = (image) => {
  cropDetails.value.name = image.label;
}

const loadCrop = async () => {
  axios.get('/api/crop/'+cropId.value).then(response => {
    cropDetails.value = Object.assign({}, response.data);
    cropOldDetails.value = Object.assign({}, response.data);
  })
}

onMounted(() => {
  cropId.value = route.value.params.id
  loadCrop();
})


</script>

<style scoped>

</style>