<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1>Inserisci un nuovo messaggio</h1>
    <form v-if="message" class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Titolo *</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="message.title" type="text" name="title" id="title" autocomplete="title" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="body" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Testo *</label>
              <div class="mt-1">
                <textarea v-model.lazy="message.body" id="body" name="body" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
              </div>
            </div>

            <div>
              <label for="category" class="block text-sm font-medium text-gray-700">Area</label>
              <select
                  v-model="message.category"
                  id="category"
                  name="category"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                >{{category.label}}</option>
              </select>
            </div>

            <div>
              <label for="visibility" class="block text-sm font-medium text-gray-700">Visibilità</label>
              <select
                  v-model="message.state"
                  id="visibility"
                  name="visibility"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option
                    v-for="visibility in visibilities"
                    :key="visibility.id"
                    :value="visibility.id"
                >{{visibility.label}}</option>
              </select>
            </div>

          </div>

        </div>

      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
              @click.prevent="cancelAction"
              type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Annulla</button>
          <button
              @click.prevent="saveMessage"
              type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Crea</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script setup>
import {ref, onMounted, } from 'vue';
import {
  useRouter,
} from 'vue-router';
import axios from 'axios'

const router = useRouter();

const message = ref({
  'title': '',
  'body': '',
  'category': '0',
  'state': '1',
})

const visibilities = ref([
  {'id': 0, 'label': 'Nascosto'},
  {'id': 1, 'label': 'Pubblicato'},
]);

const categories = ref([
  {'id': 0, 'label': 'Avvisi'},
  {'id': 1, 'label': 'Inverno'},
  {'id': 2, 'label': 'Primavera'},
  {'id': 3, 'label': 'Estate'},
  {'id': 4, 'label': 'Autunno'},
]);

const saveMessage = async () => {
  if (message.value.title !== '' && message.value.body !== '') {
    await axios.post('/api/message', message.value)
        .then(() => {
          router.push('/messaggi')
        })
        .catch(error => {
          console.debug(error.response)
        });
  } else {
    alert('E\' necessario compilare i campi obbligatori')
  }
}

const cancelAction = async () => {
  await router.push('/messaggi')
}

onMounted(async () => {

})


</script>

<style scoped>

</style>