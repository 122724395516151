import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import UserLoginView from '../views/UserLoginView.vue'
import UsersList from '../views/User/UsersList.vue'
import UserDetails from '../views/User/UserDetailsView';
import UserCreate from '../views/User/UserCreateView'
import ImagesList from '../views/Image/ImagesListView';
import ImagesMyList from '../views/Image/ImagesMyListView';
import ImageUpload from '../views/Image/ImageUploadView';
import CropsAdminList from '../views/Crop/CropAdminListView.vue'
import CropAdminDetailView from "@/views/Crop/CropAdminDetailView.vue";
import CropsList from '../views/Crop/CropListView';
import CropDetails from '../views/Crop/CropDetailView';
import CropReport from '../views/Crop/CropReportView';
import Dashboard from '../views/Dashboard/DashboardView';
import TodoList from '../views/UserLog/TodoListView';
import UserLog from '../views/UserLog/UserLogView';
import TaskCreate from '../views/UserLog/TaskCreateView';
import TaskCreateAdmin from '../views/UserLog/TaskCreateAdminView';
import TaskEdit from '../views/UserLog/TaskEditView';
import SpecUserReport from '../views/UserLog/SpecUserReportView';
import UserReportGateway from '../views/UserLog/UserReportGateway';
import ChangePassword from '../views/User/ChangePasswordView';
import MessageCreate from '../views/Message/MessageCreateView';
import MessageEdit from '../views/Message/MessageEditView';
import MessageGateway from '../views/Message/MessageGateway';
import MessageAdmin from '../views/Message/MessageAdmin';
import MessageArchive from '../views/Message/MessageArchive';

import axios from "axios";
import {user} from "@/store/userStore";

async function loadUserDetails() {
  await axios.get('/api/user/current/infos').then(response => {
    console.debug('Loading user infos from DB')
    user.loadUserInfo(response.data);
  })
  //return to;
}

async function checkAuth() {
  if (user.info.groups[0].access_level === 'user' ) {
    return '/dashboard'
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/todolist/:day?',
    name: 'todolist',
    component: TodoList,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/diario/:day?',
    name: 'userlog',
    component: UserLog,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/attivita/crea',
    name: 'taskCreate',
    component: TaskCreate,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/attivita/:id/duplica',
    name: 'taskDuplicate',
    component: TaskCreate,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/attivita/nuova',
    name: 'taskNew',
    component: TaskCreate,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/utente/:id/crea-task',
    name: 'taskCreate',
    component: TaskCreateAdmin,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/attivita/modifica/:id',
    name: 'taskEdit',
    component: TaskEdit,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/utente/report/:id/:day?',
    name: 'specUsersReport',
    component: SpecUserReport,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/report/gateway',
    name: 'usersReportGateway',
    component: UserReportGateway,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/utente/crea',
    name: 'usersCreate',
    component: UserCreate,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/utente/:id',
    name: 'usersDetails',
    component: UserDetails,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/utente/:id/modifica-password',
    name: 'changePassword',
    component: ChangePassword,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/utenti',
    name: 'usersList',
    component: UsersList,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/immagini/pagina/:page',
    name: 'paginatedImagesList',
    component: ImagesList,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/immagini',
    name: 'imagesList',
    component: ImagesList,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/mie-immagini',
    name: 'myImagesList',
    component: ImagesMyList,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/carica-foto',
    name: 'imageUpload',
    component: ImageUpload,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/colture-gestione',
    name: 'cropsAdmin',
    component: CropsAdminList,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/colture',
    name: 'cropsList',
    component: CropsList,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/coltura/:id/report',
    name: 'cropReport',
    component: CropReport,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/coltura/:id/modifica',
    name: 'cropModifica',
    component: CropAdminDetailView,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/coltura/:id',
    name: 'cropDetails',
    component: CropDetails,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/messaggio/crea',
    name: 'messagesCreate',
    component: MessageCreate,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/messaggio/modifica/:id',
    name: 'messageEdit',
    component: MessageEdit,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/messaggi',
    name: 'messagesAdmin',
    component: MessageAdmin,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/messaggi/pagina/:page',
    name: 'paginatedMessagesAdmin',
    component: MessageAdmin,
    beforeEnter: [loadUserDetails, checkAuth],
  },
  {
    path: '/stagioni/id/:id',
    name: 'messagesArchive',
    component: MessageArchive,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/stagioni',
    name: 'messagesGateway',
    component: MessageGateway,
    beforeEnter: [loadUserDetails],
  },
  {
    path: '/sys-login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/login',
    name: 'userLogin',
    component: UserLoginView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
