<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <form v-if="userProfile" class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">{{userProfile.name}}</h3>
            <p v-if="userProfile.groups[0]">Membro del gruppo: <em>{{userProfile.groups[0].name}}</em></p>
          </div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nome Utente</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="userProfile.name" type="text" name="username" id="username" autocomplete="username" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>

            <div>
              <label for="group" class="block text-sm font-medium text-gray-700">Gruppo</label>
              <select
                  v-model="groupSelected"
                  id="group"
                  name="group"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option
                    v-for="group in groupsList"
                    :key="group.id"
                    :value="group.id"
                >{{group.name}}</option>
              </select>
            </div>

            <div>
              <label for="group" class="block text-sm font-medium text-gray-700">Sesso</label>
              <select
                  v-model="userProfile.details.gender"
                  id="group"
                  name="group"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option
                    v-for="gender in genderList"
                    :key="gender.id"
                    :value="gender.id"
                >{{gender.label}}</option>
              </select>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="photo" class="block text-sm font-medium text-gray-700">Avatar</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="flex items-center">
                  <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                    <img class="h-full w-full text-gray-300" :src="userProfile.details.avatar" alt="" />
                  </span>
                  <button
                      @click="toggleNewAvatar"
                      type="button"
                      class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >{{newAvatarLabel}}</button>
                </div>
              </div>
            </div>

            <div
                :hidden="!newAvatar"
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Carica un nuovo avatar</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div class="space-y-1 text-center">
                    <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                      <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                      <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <span v-if="fileName === ''">Seleziona il file</span>
                        <span v-else>{{fileName}} (Cambia file)</span>
                        <input
                            @change="fileIsSelected"
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            class="sr-only"
                            ref="file"
                        />
                      </label>
                    </div>
                    <p class="text-xs text-gray-500">PNG, JPG, GIF fino a 2MB</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">Informazioni personali</h3>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Nome </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model.lazy="userProfile.details.first_name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Cognome </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model.lazy="userProfile.details.last_name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Indirizzo e-mail </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model.lazy="userProfile.email" id="email" name="email" type="email" autocomplete="email" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Data di nascita </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model.lazy="userProfile.details.birthday" id="birthday" name="birthday" type="date" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button @click="router.push('/utenti/')" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Annulla</button>
          <button
              @click.prevent="updateUser"
              type="submit"
              class="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ buttonSaveLabel }}</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script setup>
import {ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'

const route = ref(useRoute());
const router = ref(useRouter());
const userId = ref("");
const groupsList = ref([]);
const groupSelected = ref();
const newAvatar = ref(false);
const newAvatarLabel = ref('Modifica');
const file = ref();
const fileName = ref('');

const genderList = ref([
  {'label': 'Maschio', 'id':'m'},
  {'label': 'Femmina', 'id':'f'},
  {'label': 'Altro', 'id':'n'},
])

const userProfile = ref({
  'details': {},
  'groups': [],
})

const buttonSaveLabel = ref('Salva');

const updateUser = async () => {
  if (fileName.value !== '') {
    const formData = new FormData();
    formData.append('avatar', file.value.files[0]);
    await axios.post("/api/user/"+userId.value +"/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    delete userProfile.value.details.avatar;
  }

  userProfile.value.groups = [groupSelected.value];

  await axios.put('/api/user/'+userId.value, userProfile.value)
      .then( async () => {
        await loadUser(userId.value);
        alert('Le modifiche all\'utente sono state salvate.')
        pointSelectedGroup();
        newAvatarLabel.value = 'Modifica'
        newAvatar.value = false;
      })
      .catch( error => {
        console.debug(error.response)
      });
}

const loadUser = async (id) => {
  await axios.get('/api/user/'+id)
      .then(response => {
        userProfile.value = response.data;
        if (userProfile.value.details === null) {
          userProfile.value.details = { 'user_id': id, }
        }
      })
}

const loadGroupsList = async () => {
  await axios.get('/api/group')
      .then(response => {
        groupsList.value = response.data;
      })
}

const pointSelectedGroup = () => {
  if (userProfile.value.groups.length > 0) {
    groupSelected.value = userProfile.value.groups[0].id;
  }
}

const toggleNewAvatar = () => {
  if (newAvatar.value) {
    newAvatarLabel.value = 'Modifica'
    newAvatar.value = false;
  } else {
    newAvatarLabel.value = 'Annulla'
    newAvatar.value = true;
  }
}

const fileIsSelected = () => {
  fileName.value = file.value.value.split(/(\\|\/)/g).pop();
}

onMounted(async () => {
  userId.value = route.value.params.id;
  await loadUser(userId.value);
  await loadGroupsList();
  pointSelectedGroup();
})


</script>

<style scoped>

</style>