<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1 class="text-lg border-b-2 border-solid">Gestione dei messaggi inseriti</h1>
    <p class="mb-5">
      <a href="/#/messaggio/crea"><DocumentAddIcon class="h-5 w-5 text-gray-600 inline-block focus:outline-none" />Inserisci un messaggio</a>
    </p>
    <ul role="list" class="divide-y divide-gray-200">

      <li v-for="message in messagesList.data" :key="message.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="mt-1">
          <div class="text-sm text-gray-600">
            <p class="italic">{{message.title}}</p>
            <p class="mb-4">{{message.body}}</p>
            <p>Data: {{new Date(message.created_at * 1000).toLocaleDateString()}}</p>
            <p>Autore: {{message.author.name}}</p>
            <p>Visibilità: <span v-if="message.state === 1">Pubblico</span><span v-else>Privato</span></p>
          </div>
        </div>
        <div class="mt-5">
          <a href="#" v-if="message.state === 1" @click.prevent="toggleMessageState(message)"><GlobeAltIcon class="h-5 w-5 text-green-400 inline-block focus:outline-none" aria-hidden="true"/> Sospendi</a>
          <a href="#" v-else @click.prevent="toggleMessageState(message)"><GlobeAltIcon class="h-5 w-5 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/> Pubblica</a> |
          <a :href="'/#/messaggio/modifica/'+message.id"><PencilIcon class="h-5 w-5 text-yellow-400 inline-block focus:outline-none" aria-hidden="true" /> Modifica</a> |
          <a href="#" @click.prevent="deleteMessage(message)"><TrashIcon class="h-5 w-5 text-red-400 inline-block" aria-hidden="true" /> Elimina</a>
        </div>
      </li>

    </ul>

    <Paginator
        v-if="messagesList.links !== []"
        :paginator="messagesList"
        :route-id="'paginatedMessagesAdmin'"
    />
  </div>
</template>

<script setup>
import {ref, onMounted, watch } from "vue";
import {
  // useRouter,
  useRoute
} from 'vue-router';
import axios from "axios";
import Paginator from '../../components/Paginator'

import {
  GlobeAltIcon,
  TrashIcon,
  PencilIcon,
  DocumentAddIcon,
} from '@heroicons/vue/solid'
import router from "@/router";

const route = useRoute()

const messagesList = ref({
  'data': [],
  'links': [],
});

const loadMessageList = async (page = 1) => {

  if (parseInt(page) === 1) {
    await axios.get('/api/messages')
        .then(response => {
          messagesList.value = response.data;
        })
        .catch(error => {
          console.debug(error.response)
        });
  } else {
    await axios.get('/api/messages?page='+page)
        .then(response => {
          messagesList.value = response.data;
        })
        .catch(error => {
          console.debug(error.response)
        });
  }
}

const toggleMessageState = (message) => {
  if (confirm('Sei sicuro di voler modificare lo stato del messaggio "'+message.title+'" ?') === true) {
    axios.put('/api/message/' + message.id + '/toggle-state')
        .then(() => {
          if (message.state === 1) {
            message.state = 0;
          } else {
            message.state = 1;
          }
        })
        .catch(error => {
          console.debug(error.data);
        })
  }
};

const deleteMessage = (message) => {
  let confirmResult = confirm("Vuoi eliminare il messaggio \""+message.title+'"?');

  if (confirmResult) {
    axios.delete('/api/message/'+message.id)
        .then( () => {
          router.push('/messaggi');
          loadMessageList();
        })
        .catch( error => {
          console.debug(error.data);
        })
  }
};

onMounted( async () => {
  await loadMessageList();
})

// fetch the user information when params change
watch(
    () => route.params.page,
    async () => {
      await loadMessageList(route.params.page)
    }
)
</script>