<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1 class="text-lg border-b-2 border-solid">Leggi gli avvisi per {{seasonInfo.name}}</h1>

    <ul role="list" class="divide-y divide-gray-200">

      <li v-for="message in messagesList.data" :key="message.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="mt-1">
          <div class="text-sm text-gray-600">
            <p class="italic">{{message.title}}</p>
            <p class="mb-4">{{message.body}}</p>
          </div>
        </div>
      </li>

      <li v-if="messagesList.data.length === 0" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="mt-1">
          <div class="text-sm text-gray-600">
            <p class="mb-4">Non ci sono avvisi per questa stagione</p>
          </div>
        </div>
      </li>

    </ul>

    <Paginator
        v-if="messagesList.links !== []"
        :paginator="messagesList"
        :route-id="'paginatedMessagesAdmin'"
    />
  </div>
</template>

<script setup>
import {ref, onMounted, watch } from "vue";
import {
  // useRouter,
  useRoute
} from 'vue-router';
import axios from "axios";
import Paginator from '../../components/Paginator'

const route = useRoute()

const area = ref();
const seasonInfo = ref('');
const messagesList = ref({
  'data': [],
  'links': [],
});

const seasons = ref([
  { 'id': 1, 'ico': '/images/seasons/winter.png', 'name': 'Inverno',},
  { 'id': 2, 'ico': '/images/seasons/spring.png', 'name': 'Primavera',},
  { 'id': 3, 'ico': '/images/seasons/summer.png', 'name': 'Estate',},
  { 'id': 4, 'ico': '/images/seasons/autumn.png', 'name': 'Autunno',},
]);

const getSeasonInfo = (id) => {
  id = parseInt(id)-1;
  return seasons.value[id];
}

const loadMessageList = async (page = 1) => {

  if (parseInt(page) === 1) {
    await axios.get('/api/messages/category/'+area.value)
        .then(response => {
          messagesList.value = response.data;
        })
        .catch(error => {
          console.debug(error.response)
        });
  } else {
    await axios.get('/api/messages/category/'+area.value+'?page='+page)
        .then(response => {
          messagesList.value = response.data;
        })
        .catch(error => {
          console.debug(error.response)
        });
  }
}

onMounted( async () => {
  area.value = route.params.id;
  seasonInfo.value = getSeasonInfo(area.value);
  await loadMessageList();
})

// fetch the user information when params change
watch(
    () => route.params.page,
    async () => {
      await loadMessageList(route.params.page)
    }
)
</script>