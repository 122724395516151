<template>
  <div class="container mx-auto mb-28 px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1 class="text-lg border-b-2 border-solid">Account del sistema</h1>
    <p class="mt-2 text-sm text-gray-700">Tutti gli account presenti nel sistema.</p>

    <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
              @click="router.push('/utente/crea/')"
              type="button"
              class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Crea utente</button>
        </div>
      </div>
      <div class="-mx-4 mt-8 shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Nome</th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span class="sr-only">Modifica</span>
            </th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="user in usersList" :key="user.id">
            <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
              {{ user.name }}
            </td>
            <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-700">
                    <span class="sr-only">Open options</span>
                    <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                  </MenuButton>
                </div>

                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a :href="'/#/utente/'+user.id" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Dettagli</a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a :href="'/#/utente/'+user.id+'/modifica-password'" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Modifica Password</a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a :href="'/#/utente/report/'+user.id+'/'" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Report</a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a :href="'/#/utente/'+user.id+'/crea-task'" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Crea Attività</a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a @click.prevent="deleteUser(user)" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-red-700', 'block px-4 py-2 text-sm']">Elimina</a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { DotsVerticalIcon } from '@heroicons/vue/solid'

const router = useRouter();
const usersList = ref("");

const deleteUser = async (user) => {
  if (confirm('Stai per eliminare l\'utente '+user.name+' Sei sicuro?') === true) {
    console.debug('Deleting User with id: '+user.name);
    await axios.delete('/api/user/'+user.id).then(response => {
      console.log(response);
      alert('L\'utente è stato rimosso dal sistema');
      usersList.value = response.data;
      loadUsersList();
    })
  }
}

const loadUsersList = async () => {
  await axios.get('/api/user').then(response => {
    console.log(response);
    usersList.value = response.data;
  })
}

onMounted(async () => {
    await loadUsersList();
})


</script>