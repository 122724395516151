<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <ul role="list" class="divide-y divide-gray-200">
      <li class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <p class="text-sm font-medium text-gray-900">:: Strumenti ::</p>
          </div>
        </div>
        <div class="mt-1">
          <ul class="text-sm text-gray-600">
            <li><a href="/#/attivita/nuova"><ClipboardListIcon class="h-5 w-5 text-gray-600 inline-block" aria-hidden="true"/> Pianifica una nuova attività</a></li>
            <li><a href="/#/carica-foto"><PhotographIcon class="h-5 w-5 text-gray-600 inline-block" aria-hidden="true"/> Carica una nuova foto</a></li>
          </ul>
        </div>
      </li>
      <li class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <a href="#" class="block focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true" />
              <p class="text-sm font-medium text-gray-900">:: Informazioni::</p>
            </a>
          </div>
        </div>
        <div class="mt-1">
          <p class="text-sm text-gray-600">
            Oggi è il <em>{{new Date( Date.now() ).toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}}</em>
          </p>
          <p class="text-sm text-gray-600">
            L'alba è alle <em v-if="sunInfos !== ''">{{new Date(sunInfos.sunrise * 1000).toLocaleTimeString()}}</em>
          </p>
          <p class="text-sm text-gray-600">
            Il tramonto è alle <em v-if="sunInfos !== ''">{{ new Date(sunInfos.sunset * 1000).toLocaleTimeString() }}</em>
          </p>
        </div>
      </li>
      <li class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <span class="absolute inset-0" aria-hidden="true" />
            <p class="text-sm font-medium text-gray-900">:: Avvisi ::</p>
          </div>
        </div>
        <div class="mt-1">
          <div v-for="message in messagesList" :key="message.id" class="text-sm text-gray-600">
            <p class="italic"><InformationCircleIcon class="h-5 w-5 text-blue-400 inline-block" aria-hidden="true"/> {{message.title}}</p>
            <p class="mb-4">{{message.body}}</p>
          </div>
        </div>
      </li>
      <li class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <span class="absolute inset-0" aria-hidden="true" />
            <p class="text-sm font-medium text-gray-900">:: Avvisi dalle colture ::</p>
          </div>
        </div>
        <div class="mt-1">
          <div v-for="crop in cropsList" :key="crop.id" class="text-sm text-gray-600">
            <p class="italic"><img :src="crop.ico_path" :alt="crop.name" class="inline-block w-5" />&nbsp;{{crop.name}}</p>
            <p v-if="crop.last_reading.sensor_03 < 300" class="mb-4">Complimenti! La tua coltivazione è ben innaffiata 😊</p>
            <p v-else-if="crop.last_reading.sensor_03 < 600" class="mb-4">Controlla! Le piantine potrebbero avere bisogno di acqua 🤔</p>
            <p v-else-if="crop.last_reading.sensor_03 > 600" class="mb-4">Attenzione! Le piantine hanno urgente bisogno di acqua 😟</p>
          </div>
        </div>
      </li>
      <li class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <p class="text-sm font-medium text-gray-900">:: Amici a cui fare gli auguri ::</p>
            <ul v-if="birthDaysList.length > 0">
              <li v-for="birthDay in birthDaysList" :key="birthDay.id">{{birthDay.name}}</li>
            </ul>
            <p v-else class="text-sm text-gray-600">Oggi non si festeggia nessun compleanno!</p>
          </div>
        </div>
      </li>
    </ul>

  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import {ClipboardListIcon, PhotographIcon, InformationCircleIcon} from "@heroicons/vue/solid";

const sunInfos = ref('');
const birthDaysList = ref([]);
const messagesList = ref([]);
const cropsList = ref([]);


onMounted( async () => {

  await axios.get('/api/info')
      .then(response => {
        sunInfos.value = response.data;
      })
      .catch(error => {
        console.debug(error.response)
      });

  await axios.get('/api/user/birth-today')
      .then(response => {
        birthDaysList.value = response.data;
      })
      .catch(error => {
        console.debug(error.response)
      });

  await axios.get('/api/messages/dashboard')
      .then(response => {
        messagesList.value = response.data;
      })
      .catch(error => {
        console.debug(error.response)
      });

  await axios.get('/api/crop/all/with-last-reading')
      .then(response => {
        cropsList.value = response.data;
      })
      .catch(error => {
        console.debug(error.response)
      });

});

</script>
<style>

</style>
