<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1 class="text-lg border-b-2 border-solid">Pubblica una nuova foto</h1>
    <form
        ref="form"
        class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5"
    >
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div
                class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Scegli la foto</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div class="space-y-1 text-center">
                    <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                      <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                      <label for="image" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <span v-if="fileName === ''">Seleziona il file</span>
                        <span v-else>{{fileName}} (Cambia file)</span>
                        <input
                            @change="fileIsSelected"
                            id="image"
                            name="image"
                            type="file"
                            class="sr-only"
                            ref="image"
                        />
                      </label>
                    </div>
                    <p class="text-xs text-gray-500">PNG, JPG, GIF fino a 2MB</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label for="about" class="block text-sm font-medium text-gray-700"> Descrizione </label>
              <div class="mt-1">
                <textarea id="description" name="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
              </div>
              <p class="mt-2 text-sm text-gray-500">Se vuoi, scrivi una breve descrizione</p>
            </div>


          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
                @click.prevent="cancelUpload"
                type="button"
                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >Annulla</button> &nbsp;
            <button
                @click.prevent="uploadImage"
                type="submit"
                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Carica </button>
          </div>
        </div>

      </div>
    </form>

  </div>
</template>

<script setup>
import {ref, } from 'vue';
import { useRouter } from 'vue-router'
import axios from 'axios'

const router = ref(useRouter());
const form = ref();
const image = ref();
const fileName = ref('');

const uploadImage = () => {

  const formData = new FormData(form.value);

  axios.post("/api/image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
      .then( () => {
        router.value.push('/mie-immagini/')
      });

}

const cancelUpload = async () => {
  await router.value.push('/mie-immagini/')
}

const fileIsSelected = () => {
  fileName.value = image.value.value.split(/(\\|\/)/g).pop();
}

</script>

<style scoped>

</style>