<template>
  <div class="container mt-20 mx-auto px-4 sm:px-6 lg:px-8 p-2 ">
    <div v-if="!email" class="grid grid-cols-3 gap-4 sm:grid-cols-2">
      <div
          @click.prevent="setUser(person.email)"
          v-for="person in usersList"
          :key="person.id"
          class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
      >
        <div class="flex-shrink-0 h-10 w-10">
          <span class="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                <img
                    :src="person.details.avatar"
                    class="h-full w-full text-gray-300"
                    alt=""
                />
          </span>
        </div>
        <div class="flex-1 min-w-0">

        </div>
      </div>
    </div>

    <div v-if="email">
      <div class="text-right mb-8">
        <a
            @click.prevent="resetUser"
            href="#"
            class="text-white"
        >&leftarrow; Indietro</a>
      </div>
      <div class="text-center mb-8">
        <img
            v-for="item in cards"
            :key="item.name"
            @click="addCharToPassword(item.id)"
            :src="item.href"
            :alt="item.name"
            class="inline-block h-20 w-20 mx-4 my-1 rounded-md border border-gray-300"
        />
      </div>

      <div class="text-center">
        <img
            v-for="item in slots"
            :key="item.id"
            :src="item.href"
            :alt="item.name"
            :class="{ 'sepia' : (item.id <= password.length ) }"
            class="inline-block h-18 w-14 mx-1 rounded-md border border-gray-900"
        />
      </div>

      <div class="px-4 py-3 text-right sm:px-6">
        <button
            @click.prevent="resetPassword"
            type="submit"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >Reset Password</button>&nbsp;
        <button
            @click.prevent="logIn"
            type="submit"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500-500"
        >Login</button>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from 'vue'
import {
  useRouter,
  // useRoute
} from 'vue-router'
import axios from 'axios'

const router = useRouter()
// const route = useRoute()

const cards = [
  { id: '1', name: 'Carota', href: '/images/crops/carota.png'},
  { id: '2', name: 'Cavolfiore', href: '/images/crops/cavolfiore.png'},
  { id: '3', name: 'Melanzana', href: '/images/crops/melanzana.png'},
  { id: '4', name: 'Patata', href: '/images/crops/patata.png'},
  { id: '5', name: 'Pomodoro', href: '/images/crops/pomodoro.png'},
  // { id: '6', name: 'Peperone', href: '/images/auth/autent_peperone.png'},
]

const slots = [
  { id: '1', visibility: 'light', name: 'uno', href: '/images/auth/autent_box1.png'},
  { id: '2', visibility: 'light', name: 'due', href: '/images/auth/autent_box2.png'},
  { id: '3', visibility: 'light', name: 'tre', href: '/images/auth/autent_box3.png'},
  { id: '4', visibility: 'light', name: 'quattro', href: '/images/auth/autent_box4.png'},
  { id: '5', visibility: 'light', name: 'cinque', href: '/images/auth/autent_box5.png'},
]

const email = ref("")
const password = ref("")
const usersList = ref("");

onMounted(() => {
  axios.get('/api/user/by-access-level/USER').then(response => {
    usersList.value = response.data;
  })
})
const setUser = (user)=> {
  email.value = user;
}

const resetUser = ()=> {
  email.value = ""
}

const addCharToPassword = (charToAdd)=> {
  if (password.value.length < 5) {
    password.value += charToAdd;
  }
  console.debug("Password is now: "+password.value);
}

const resetPassword = ()=> {
  password.value = "";
  console.debug("Password is now: "+password.value);
}

const logIn = ()=>{
  axios.get('/sanctum/csrf-cookie').then(response => {
    console.debug(response)
    axios.post('/login', {
      email: email.value,
      password: password.value,
    })
      .then( () => {
        router.push({name: 'dashboard'})
      })
      .catch(error => {
        console.debug(error.response)
      })
  });
}

onBeforeMount( async () => {
  console.debug('Checking if user is logged in');
  if ( document.cookie.match(/^(.*;)?\s*XSRF-TOKEN\s*=\s*[^;]+(.*)?$/)
      // && document.cookie.match(/^(.*;)?\s*laravel_session\s*=\s*[^;]+(.*)?$/)
  ) {
    console.debug('Cookie presenti!');
    router.push({name: 'dashboard'})
  }
})

</script>

