<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1>Attività svolte dall'utente <span v-if="dayTarget === ''">oggi </span><strong v-if="dayTarget === ''">{{new Date( Date.now() ).toLocaleDateString()}}</strong>
      <strong v-else>{{new Date( parseInt(dayTarget) ).toLocaleDateString()}}</strong></h1>
    <p>
      <a @click.prevent="router.push('/utente/report/'+userTarget+'/'+dayPrev)" href="/#/">
        <ChevronDoubleLeftIcon class="h-5 w-5 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/>Prec.</a>
      <span v-if="dayNext !== ''"> | </span>
      <a v-if="dayNext !== ''" @click.prevent="router.push('/utente/report/'+userTarget+'/'+dayNext)" href="/#/">
        Succ.<ChevronDoubleRightIcon class="h-5 w-5 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/></a>
    </p>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="task in todoList" :key="task.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <span class="absolute inset-0" aria-hidden="true" />
            <p class="text-sm font-medium text-gray-900">{{ task.sender }}</p>
            <p class="text-sm text-gray-500">{{ task.label }}</p>
          </div>
          <time :datetime="task.scheduled_on" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{{ new Date(task.scheduled_on * 1000).toLocaleDateString() }}</time>
        </div>
        <div class="mt-1">
          <p class="text-sm text-gray-600">
            {{ task.description }}
          </p>
        </div>
      </li>
    </ul>

  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useRoute,useRouter} from 'vue-router';
import axios from "axios";

import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid'

const router = useRouter();
const route = ref(useRoute());


const todoList = ref([]);
const userTarget = ref('');
const dayTarget = ref('');
const dayPrev = ref('');
const dayNext = ref('');

const loadUserReport = () => {
  let endpoint;
  console.debug('DayTarget: '+dayTarget.value)
  if (dayTarget.value === '') {
    let today = new Date( Date.now() );
    today = today.toISOString().split('T')[0];
    endpoint = '/api/userlog/'+today+'/utente/'+userTarget.value;
  } else {
    let date = new Date( parseInt(dayTarget.value) );
    date = date.toISOString().split('T')[0];
    endpoint = '/api/userlog/'+date+'/utente/'+userTarget.value;
  }
  axios.get(endpoint)
      .then(response => {
        todoList.value = response.data;
      })
};

const prepareDate = () => {
  if (dayTarget.value !== '') {
    let today = new Date( Date.now() );
    today.setHours(0,0,0);
    let target = new Date( parseInt(dayTarget.value) );
    // console.debug('Confronto target ('+target+') con today ('+today+')')
    if (target.getTime() >= today.getTime()) {
      dayTarget.value = ''
    }
  }
  setDayNext();
  setDayPrev();
}

const setDayPrev = () => {
  let tmpDate = '';
  if (dayTarget.value === '') {
    tmpDate = new Date( Date.now() )
  } else {
    tmpDate = new Date( parseInt(dayTarget.value) );
  }
  tmpDate.setDate( tmpDate.getDate() -1 );
  dayPrev.value = tmpDate.getTime();
}

const setDayNext = () => {
  let tmpDate = '';
  if ( (dayTarget.value === '')  ) {
    //When today's tasks are liste non need for prev button
    dayNext.value = ''
  } else {
    tmpDate = new Date( parseInt(dayTarget.value) );
    tmpDate.setDate( tmpDate.getDate() + 1 );
    dayNext.value = tmpDate.getTime();
  }
}

onMounted( () => {
  userTarget.value = route.value.params.id;
  dayTarget.value = route.value.params.day;
  prepareDate();

  // loadUserDetails();
  loadUserReport();

});

watch(() => route.value.params.day, () => {
  if (route.value.name === 'specUsersReport') {
    dayTarget.value = route.value.params.day;
    prepareDate();
    todoList.value = [];
    loadUserReport();
  }
})
</script>

<style scoped>

</style>