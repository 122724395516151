<template>
  <div class="container mt-20 mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Credenziali di accesso</h3>
            <p class="mt-1 text-sm text-gray-600">Inserisci username e password per accedere al sistema.</p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form
              action="#"
              method="POST"
              @submit.prevent="logIn"
          >
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <label for="userId" class="block text-sm font-medium text-gray-700">E-mail</label>
                    <input
                        v-model="email"
                        type="text"
                        name="userId"
                        id="userId"
                        autocomplete="Nome utente"
                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                    <input
                        v-model="password"
                        type="password"
                        name="last-name"
                        id="last-name"
                        autocomplete="family-name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                    type="submit"
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue'
import {
  useRouter,
  // useRoute
} from 'vue-router'
import axios from 'axios'

const router = useRouter()
// const route = useRoute()

const email = ref("")
const password = ref("")

const logIn = async () => {
  axios.get('/sanctum/csrf-cookie').then( () => {

    axios.post('/login', {
      email: email.value,
      password: password.value,
    })
    .then( () => {
      router.push({name: 'dashboard'})
    })
    .catch(error => {
      console.debug(error.response)
    })
  });
}



onBeforeMount( async () => {
  console.debug('Checking if user is logged in');
  if (document.cookie.length !== 0) {
    console.debug('There are cookies')
    console.debug(document.cookie)
    // document.cookie = "name=laravel_session; max-age=0";
    document.cookie = "name=XSRF-TOKEN; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax; Secure\"";
  }
})

</script>

