<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1 class="text-lg border-b-2 border-solid">Foto condivise</h1>
    <p class="mb-5"><a href="/#/carica-foto"><PhotographIcon class="h-5 w-5 text-gray-600 inline-block" aria-hidden="true"/> Carica una nuova foto</a></p>
    <ul
        v-if="imageList.data.length > 0"
        role="list"
        class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
    >
      <li
          v-for="image in imageList.data"
          :key="image.id"
          class="relative"
      >
        <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img :src="$beDomain+image.path" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
          <button type="button" class="absolute inset-0 focus:outline-none">
            <span class="sr-only">View details for image</span>
          </button>
        </div>

        <p>
          <button
              @click.prevent="openModal(image)"
              type="button"
              class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Dettagli</button>
        </p>
      </li>
    </ul>

    <div
        v-else
        class="rounded-md bg-blue-50 p-4"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">Non hai ancora condiviso una foto con il gruppo</p>
          <p class="mt-3 text-sm md:mt-0 md:ml-6">
            <a
                href="/#/carica-foto"
                class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
            > Pubblica una foto <span aria-hidden="true">&rarr;</span></a>
          </p>
        </div>
      </div>
    </div>

    <image-modal
        :image="imageToDisplay"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, provide } from 'vue'
import {
  // useRouter,
  // useRoute
} from 'vue-router'
import axios from 'axios'
import { InformationCircleIcon, PhotographIcon } from '@heroicons/vue/solid'
import ImageModal from '../../components/ImageModalPopup'

const modalVisibility = ref(false)
provide('modalVisibility', modalVisibility)

const imageToDisplay = ref('')
const imageList = ref({
  'data': [],
});

const loadImageList = async () => {
  await axios.get('/api/image/user').then(response => {
    imageList.value = response.data;
  })
}

const openModal = (imageUrl) => {
  modalVisibility.value = false;
  modalVisibility.value = true;
  imageToDisplay.value = imageUrl
}

onMounted(async () => {
  await loadImageList();
})


</script>