<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1>Attività svolte <span v-if="dayTarget === ''">oggi </span><strong v-if="dayTarget === ''">{{new Date( Date.now() ).toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}}</strong>
    <strong v-else>{{new Date( parseInt(dayTarget) ).toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}}</strong></h1>
    <p>
      <a @click.prevent="router.push('/diario/'+dayPrev)" :href="'/#/todolist/'+dayNext">
        <ChevronDoubleLeftIcon class="h-5 w-5 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/>Prec.</a>
      <span v-if="dayNext !== ''"> | </span>
      <a v-if="dayNext !== ''" @click.prevent="router.push('/diario/'+dayNext)" :href="'/#/todolist/'+dayNext">
        Succ.<ChevronDoubleRightIcon class="h-5 w-5 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/></a>
    </p>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="task in todoList" :key="task.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <p class="text-sm text-gray-500"><ClipboardCheckIcon class="h-5 w-5 text-green-400 inline-block" aria-hidden="true"/> {{ task.label }}</p>
          </div>
        </div>
        <div class="mt-1">
          <p class="line-clamp-2 text-sm text-gray-600">
            {{ task.description }}
          </p>
          <p v-if="task.crop !== null" class="text-sm"> <img :src="task.crop.ico_path" :alt="task.crop.name" class="inline-block w-5" /> {{ task.crop.name }} </p>
        </div>
      </li>
    </ul>

  </div>
</template>

<script setup>
import {onMounted} from "vue";
import {useRoute,useRouter} from 'vue-router';
import {ref, watch} from 'vue';
import axios from "axios";

import { ClipboardCheckIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid'

const router = useRouter();
const route = ref(useRoute());

const userDetails = ref({});
const todoList = ref([]);
const dayTarget = ref('');
const dayPrev = ref('');
const dayNext = ref('');

const loadUserDetails = () => {
  axios.get('/api/user/current/infos').then(response => {
    userDetails.value = response.data;
  })
};

const loadTodolist = () => {
  let endpoint;
  if (dayTarget.value === '') {
    endpoint = '/api/userlog';
  } else {
    let date = new Date( parseInt(dayTarget.value) );
    date = date.toISOString().split('T')[0];
    endpoint = '/api/userlog/'+date;
  }
  axios.get(endpoint)
      .then(response => {
        todoList.value = response.data;
      })
};



const prepareDate = () => {
  if (dayTarget.value !== '') {
    let today = new Date( Date.now() );
    today.setHours(0,0,0);
    let target = new Date( parseInt(dayTarget.value) );
    // console.debug('Confronto target ('+target+') con today ('+today+')')
    if (target.getTime() >= today.getTime()) {
      dayTarget.value = ''
    }
  }
  setDayNext();
  setDayPrev();
}

const setDayPrev = () => {
  let tmpDate = '';
  if (dayTarget.value === '') {
    tmpDate = new Date( Date.now() )
  } else {
    tmpDate = new Date( parseInt(dayTarget.value) );
  }
  tmpDate.setDate( tmpDate.getDate() -1 );
  dayPrev.value = tmpDate.getTime();
  // dayPrev.value = tmpDate.toISOString().split('T')[0];
}

const setDayNext = () => {
  let tmpDate = '';
  if ( (dayTarget.value === '')  ) {
    //When today's tasks are liste non need for prev button
    dayNext.value = ''
  } else {
    tmpDate = new Date( parseInt(dayTarget.value) );
    tmpDate.setDate( tmpDate.getDate() + 1 );
    dayNext.value = tmpDate.getTime();
  }
  // dayNext.value = tmpDate.toISOString().split('T')[0];
}

onMounted( () => {
  dayTarget.value = route.value.params.day;
  prepareDate();

  loadUserDetails();
  loadTodolist();

});

watch(() => route.value.params.day, () => {
  if (route.value.name === 'userlog') {
    dayTarget.value = route.value.params.day;
    prepareDate();
    todoList.value = [];
    loadTodolist();
  }
})
</script>

<style scoped>

</style>