<template>
  <div class="home text-center pt-10">
    <a href="/#/login">
      <img src="/images/splash_trasp.png" alt="Ortomagico - As.In.O." />
    </a>
  </div>
</template>

<script>
// @ is an alias to /src

</script>
<style>
#app {
  //background-image: url("http://localhost/images/splash.jpg") !important;
}
</style>
