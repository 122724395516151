<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1>Cambia la password per l'utente {{userProfile.name}}</h1>
    <form class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Password</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="password" type="password" name="password" id="password" autocomplete="password" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Conferma password</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="passwordConfirm" type="password" name="confirmPassword" id="confirmPassword" autocomplete="confirmPassword" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Annulla</button>
          <button
              @click.prevent="changePassword"
              type="submit"
              class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Salva</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script setup>
import {ref, onMounted, } from 'vue';
import {
    useRouter,
    useRoute,
} from 'vue-router';
import axios from 'axios'

const router = useRouter();
const route = ref(useRoute());

const userId = ref();
const password = ref();
const passwordConfirm = ref();
const userProfile = ref({});

const changePassword = async () => {
  if (password.value === passwordConfirm.value) {
    await axios.put('/api/user/'+userId.value, { 'password': password.value })
        .then(() => {
          router.push('/utenti')
        })
        .catch(error => {
          console.debug(error.response)
        });
  } else {
    alert('Le due password non corrispondono')
  }
}

const loadUser = async (id) => {
  await axios.get('/api/user/'+id)
      .then(response => {
        userProfile.value = response.data;
        if (userProfile.value.details === null) {
          userProfile.value.details = { 'user_id': id, }
        }
      })
}

onMounted(async () => {
  userId.value = route.value.params.id;
  await loadUser(userId.value);
})


</script>

<style scoped>

</style>