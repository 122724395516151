import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './index.css'
import './assets/tailwind.css'

const app = createApp(App);

// app.config.globalProperties.$beDomain = '//.'+window.location.hostname

axios.defaults.withCredentials = true
// axios.defaults.baseURL = app.config.globalProperties.$beDomain = '//'+window.location.hostname
axios.defaults.baseURL = app.config.globalProperties.$beDomain = '//be.'+window.location.hostname

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (401 === error.response.status) {
        router.push('/login/');
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});

app.use(store)
    .use(router)
    .use(VueAxios, axios)
    .mount('#app')
