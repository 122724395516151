<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1>Le diverse colture</h1>
    <div class="flex flex-wrap justify-center">
      <div v-for="crop in cropList.data" :key="crop.id" class="w-1/3 my-5 flex flex-col justify-center">
        <a class="inline-block h-20 w-20 rounded-3xl m-auto border border-black shadow" :href="'/#/coltura/'+crop.id">
          <img
              class="inline-block h-20 w-20"
              :src="crop.ico_path"
              :alt="crop.name" />
        </a>
        <h2 class="text-center">
          <a :href="'/#/coltura/'+crop.id">{{crop.name}}</a>
        </h2>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  // useRouter,
  // useRoute
} from 'vue-router'
import axios from 'axios'

const cropList = ref("");

onMounted(() => {
  axios.get('/api/crop')
      .then(response => {
        cropList.value = response.data;
        console.log(response);
      })
})


</script>

<style scoped>

</style>