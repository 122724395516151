<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1>Dettagli coltura {{cropDetails.name}}</h1>
    <div class="">
      <div class="my-5">
        <img
            :src="cropDetails.ico_path"
            :alt="cropDetails.name"
            class="inline-block h-20 w-20 rounded-3xl m-auto border border-black shadow"
        />
      </div>

      <div>
        <h2>Letture di {{ new Date( reading.read_at * 1000).toLocaleString('it-IT', { timeZone: 'UCT', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:"2-digit" }) }}</h2>
      </div>

      <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-8 flex flex-col">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">

                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Sensore</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Valore</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                  </thead>

                  <tbody class="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <img src="/images/sensors/Temp_sole.png" class="h-10" alt="Temperatura al sole" />
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-gray-500">
                      <span>Temperatura dell'aria al sole {{ reading.sensor_00 }}° C</span>
                      <span v-if="reading.sensor_00 <= 4">La temperatura è molto bassa! Proteggi le tue piantine riparandole dal freddo</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <img src="/images/sensors/Temp_ombra.png" class="h-10" alt="Temperatura all'ombra" />
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-gray-500">
                      <span>Temperatura dell'aria all'ombra {{ reading.sensor_01 }}° C</span>
                      <span v-if="reading.sensor_01 <= 4">La temperatura è molto bassa! Proteggi le tue piantine riparandole dal freddo</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <img src="/images/sensors/Umid_ambient.png" class="h-10" alt="Umidità dell'aria" />
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-gray-500">Umidità dell'aria {{ reading.sensor_02 }}%</td>
                  </tr>
                  <tr>
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <img src="/images/sensors/Umid_terra.png" class="h-10" alt="Umidità del suolo" />
                    </td>
                    <td class="whitespace-normal px-3 py-4 text-sm text-gray-500">
                      <span v-if="reading.sensor_03 < 300">Complimenti! La tua coltivazione è ben innaffiata 😊</span>
                      <span v-else-if="reading.sensor_03 < 600">Controlla! Le piantine potrebbero avere bisogno di acqua 🤔</span>
                      <span v-else-if="reading.sensor_03 > 600">Attenzione! Le piantine hanno urgente bisogno di acqua 😟</span>
                    </td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  useRoute
  // useRouter,
  // useRoute
} from 'vue-router'
import axios from 'axios'


const route = ref(useRoute())
const cropId = ref("");
const cropDetails = ref("");
const reading = ref("");

onMounted(() => {
  cropId.value = route.value.params.id

  axios.get('/api/crop/'+cropId.value).then(response => {
    cropDetails.value = response.data;
  })

  axios.get('/api/crop/'+cropId.value+'/last-reading').then(response => {
    reading.value = response.data;
  })

})


</script>

<style scoped>

</style>