<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1 class="text-lg border-b-2 border-solid">
      Compiti per <span v-if="dayTarget === ''">oggi </span> <br>
      <a v-if="dayPrev !== ''" @click.prevent="router.push('/todolist/'+dayPrev)" :href="'/#/todolist/'+dayNext">
      <ChevronDoubleLeftIcon class="h-8 w-8 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/></a>
      <strong v-if="dayTarget === ''">{{new Date( Date.now() ).toLocaleString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}}</strong>
      <strong v-else>{{new Date( parseInt(dayTarget) ).toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}}</strong>
      <a @click.prevent="router.push('/todolist/'+dayNext)" :href="'/#/todolist/'+dayNext">
      <ChevronDoubleRightIcon class="h-8 w-8 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/></a>
    </h1>
    <p class="mb-5">
      <a v-if="dayPrev !== ''" @click.prevent="router.push('/todolist/'+dayPrev)" :href="'/#/todolist/'+dayNext" style="display:none;">
        <ChevronDoubleLeftIcon class="h-8 w-8 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/>Prec.</a>
      <span v-if="dayPrev !== ''" style="display:none;"> | </span>
      <a href="/#/attivita/nuova"><ClipboardListIcon class="h-8 w-8 text-gray-600 inline-block" aria-hidden="true"/> Nuova attività</a> <span style="display:none;">|</span>
      <a @click.prevent="router.push('/todolist/'+dayNext)" :href="'/#/todolist/'+dayNext" style="display:none;">
      Succ.<ChevronDoubleRightIcon class="h-8 w-8 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/></a>
    </p>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(task, index) in todoList" :key="task.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
        <p v-if="task.crop !== null && (index === 0 || task.crop !== todoList[index-1].crop)">
          <img :src="task.crop.ico_path" :alt="task.crop.name" class="inline-block w-5" /> {{ task.crop.name }}
        </p>
        <div class="flex justify-between space-x-3">
          <div class="min-w-0 flex-1">
            <p class="text-sm font-medium text-gray-900">
              {{ task.sender }}
            </p>
            <p class="text-sm text-gray-500">
              <span class="text-base">{{ task.label }}</span>
              <a href="#" v-if="task.state === 1" @click.prevent="toggleTaskState(task)"><CheckCircleIcon class="h-9 w-9 text-green-400 inline-block focus:outline-none" aria-hidden="true"/></a>
              <a href="#" v-else @click.prevent="toggleTaskState(task)"><CheckCircleIcon class="h-9 w-9 text-gray-600 inline-block focus:outline-none" aria-hidden="true"/></a>
              <a :href="'/#/attivita/modifica/'+task.id"><ArrowCircleRightIcon class="h-9 w-9 text-yellow-400 inline-block focus:outline-none" aria-hidden="true" /></a>
              <a :href="'/#/attivita/'+task.id+'/duplica/'"><DuplicateIcon class="inline-block mx-1 rounded-full bg-yellow-400 h-6 w-6 outline outline-yellow-400 text-white inline-block focus:outline-none" aria-hidden="true" /></a>
              <a href="#" @click.prevent="deleteTask(task)"><XCircleIcon class="h-9 w-9 text-red-400 inline-block" aria-hidden="true" /></a>
            </p>
            <p v-if="task.crop !== null" class="text-sm">  </p>
          </div>
        </div>
        <div class="mt-1">
          <p class="text-sm text-gray-600">
            {{ task.description }}
          </p>
        </div>
      </li>
    </ul>

  </div>
</template>

<script setup>
import {ref, watch, onMounted} from "vue";
import {useRoute,useRouter} from 'vue-router';
import axios from "axios";

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ClipboardListIcon,
  ArrowCircleRightIcon,
  XCircleIcon,
  CheckCircleIcon,
  DuplicateIcon,
} from '@heroicons/vue/solid'

const router = useRouter();
const route = ref(useRoute());

const userDetails = ref({});
const todoList = ref([]);
const dayTarget = ref('');
const dayNext = ref('');
const dayPrev = ref('');

const loadUserDetails = () => {
  axios.get('/api/user/current/infos').then(response => {
    userDetails.value = response.data;
  })
};

const loadTodolist = () => {
  let endpoint;

  if (dayTarget.value === '') {
    endpoint = '/api/todolist';
  } else {
    let date = new Date( parseInt(dayTarget.value) );
    date = date.toISOString().split('T')[0];
    endpoint = '/api/todolist/'+date;
  }
  axios.get(endpoint)
      .then(response => {
        todoList.value = response.data;
      })
};

const toggleTaskState = (task) => {
  if (confirm('Sei sicuro di voler modificare lo stato dell\'attività "'+task.label+'" ?') === true) {
    axios.put('/api/task/' + task.id + '/toggle-state')
        .then(() => {
          if (task.state === 1) {
            task.state = 0;
          } else {
            task.state = 1;
          }
        })
        .catch(error => {
          console.debug(error.data);
        })
  }
};

const deleteTask = (task) => {
  let confirmResult = confirm("Vuoi eliminare l'attività \""+task.label+'"?');

  if (confirmResult) {
    axios.delete('/api/task/'+task.id)
        .then( () => {
          loadTodolist();
        })
        .catch( error => {
          console.debug(error.data);
        })
  }
};

const prepareDate = () => {

  if (dayTarget.value !== '') {
    let today = new Date( Date.now() );
    today.setHours(23,59,59);
    let target = new Date( parseInt(dayTarget.value) );

    console.debug('Confronto target ('+target+') con today ('+today+')')
    if (target.getTime() <= today.getTime()) {
      dayTarget.value = ''
    }
  }

  setDayNext();
  setDayPrev();
}

const setDayNext = () => {
  let tmpDate = '';
  if (dayTarget.value === '') {
    tmpDate = new Date( Date.now() )
  } else {
    tmpDate = new Date( parseInt(dayTarget.value) );
  }
  tmpDate.setDate( tmpDate.getDate() + 1 );
  dayNext.value = tmpDate.getTime();
  // dayNext.value = tmpDate.toISOString().split('T')[0];
}

const setDayPrev = () => {
  // let today = new Date( Date.now() );
  let tmpDate = '';
  if ( (dayTarget.value === '')  ) {
    //When today's tasks are liste non need for prev button
    dayPrev.value = ''
  } else {
    tmpDate = new Date( parseInt(dayTarget.value) );
    tmpDate.setDate( tmpDate.getDate() - 1 );
    dayPrev.value = tmpDate.getTime();
  }
  // dayNext.value = tmpDate.toISOString().split('T')[0];
}

onMounted( () => {
  dayTarget.value = route.value.params.day;
  prepareDate();

  loadUserDetails();
  loadTodolist();

});

watch(() => route.value.params.day, () => {
  if (route.value.name === 'todolist') {
    dayTarget.value = route.value.params.day;
    prepareDate();
    todoList.value = [];
    loadTodolist();
  }
})
</script>

<style scoped>

</style>