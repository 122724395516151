<template>
  <header>
    <div v-if="routeName === 'dashboard'" class="mx-auto px-4 sm:px-6 lg:px-8 text-white text-lg font-bold">
      <AdminMenu v-if="user.info.groups[0].access_level === 'ADMIN'" /> Benvenuto {{user.info.name}}
      <LogoutIcon @click="logout" class="rounded border-white border-2 h-5 w-5 text-white inline-block" /></div>
    <div v-if="routeName === 'dashboard'" class="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8 flex flex-wrap gap-y-4">
      <div class="w-1/2 px-0.5">
          <span class="inline-block h-28 w-28 rounded-lg overflow-hidden bg-gray-100">
            <svg v-if="user.info.details === null" class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <img v-else :src="user.info.hasOwnProperty('details') ? user.info.details.avatar : ''" alt="" class="inline-block h-28 w-28 rounded-lg overflow-hidden bg-gray-100">
          </span>
      </div>
      <div class="w-1/4 px-0.5">
        <a href="/#/colture/">
          <img :src="'/images/buttons/ico_coltura.png'" alt="" class="w-full inline-block" />
        </a>
      </div>
      <div class="w-1/4 px-0.5">
        <a href="/#/report/gateway">
          <img :src="'/images/buttons/ico_report.png'" alt="" class="w-full" />
        </a>
      </div>
      <div class="w-1/4 px-0.5">
        <a href="/#/todolist/">
          <img :src="'/images/buttons/ico_todolist.png'" alt="" class="w-full inline-block" />
        </a>
      </div>
      <div class="w-1/4 px-0.5">
        <a href="/#/stagioni">
          <img :src="'/images/buttons/ico_calendario.png'" alt="" class="w-full inline-block" />
        </a>
      </div>
      <div class="w-1/4 px-0.5">
        <a @click="exitWarning" href="https://t.me/+1O_cDM_crbMzYjBk">
          <img :src="'/images/buttons/ico_social.png'" alt="" class="w-full inline-block" />
        </a>
      </div>
      <div class="w-1/4 px-0.5">
        <a href="/#/mie-immagini/">
          <img :src="'/images/buttons/ico_gallery.png'" alt="" class="w-full inline-block" />
        </a>
      </div>
    </div>

    <div v-else-if="(routeName === 'userLogin') || (routeName === 'login') || (routeName === 'home')">

    </div>

    <div v-else class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-wrap gap-y-4">
      <div class="w-1/2 px-0.5">
          <span class="inline-block h-28 w-28 rounded-lg overflow-hidden bg-gray-100">
            <svg v-if="user.info.details === null" class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            <img v-else :src="user.info.hasOwnProperty('details') ? user.info.details.avatar : ''" alt="" class="inline-block h-28 w-28 rounded-lg overflow-hidden bg-gray-100">
          </span>
      </div>
      <div class="w-1/4 px-0.5">
<!--          <img :src="baseUrl+'/images/buttons/ico_coltura.png'" alt="" class="w-full inline-block" />-->
      </div>
      <div class="w-1/4 px-0.5">
        <a href="/#/dashboard/">
          <img :src="'/images/buttons/ico_home.png'" alt="" class="w-full" />
        </a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { user } from '@/store/userStore';
import { useRouter, useRoute } from 'vue-router';
import { ref, watch, onMounted } from "vue";
import AdminMenu from './AdminMenu';
import { LogoutIcon } from '@heroicons/vue/solid';
import axios from 'axios';

const route = ref(useRoute());
const router = useRouter();
const routeName = ref('');

const exitWarning = (event) => {
  if (confirm("Stai per lasciare As.In.O. e aprire il gruppo Telegram di OrtoMagico. Vuoi procedere?") === false) {
    event.preventDefault();
  }
}

const logout = () => {
  if (confirm("Stai per effettuare il logou: sei sicuro di voler uscire da As.In.O.?") === true) {
    console.debug('Requesting logout')
    axios.post('/logout')
        .then( () => {
          console.debug('Logged out')
          router.push('/login');
        })
        .catch(error => {
          console.debug(error.response);
        })
  }
}

onMounted(() => {
  routeName.value = route.value.name;
  console.debug(user.info)
})

watch(() => route.value.name, () => {
  routeName.value = route.value.name;
})
</script>

<style scoped>

</style>