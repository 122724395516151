
import { reactive } from 'vue';

export const user = reactive({
    info: {},

    loadUserInfo(userInfo) {
        this.info = userInfo
    },

    deleteUSerInfo() {
        this.info = {}
    },

});