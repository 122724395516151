<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div :class="{'hidden' : !open}" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!--
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
        :class="{ 'opacity-0' : !open }"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>

    <div
        @click="closeModal"
        class="fixed z-10 inset-0 overflow-y-auto"
    >
      <div
          class="flex items-end sm:items-center justify-center max-h-screen p-4 text-center sm:p-0"
      >
        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div
            :class="{ 'opacity-0' : !open }"
            class="max-h-screen relative bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all"
        >
          <div class="max-h-screen">
            <div @click="closeModal" class="text-right mb-2 text-sm">Chiudi <x-icon class="h-5 w-5 text-gray-600 inline-block" aria-hidden="true"/></div>
            <div class="mx-auto flex items-center justify-center rounded-full">
              <!-- Here the image -->
              <img :src="$beDomain+imageInfo.path" alt="" class="max-h-44" />
            </div>
            <div class="mt-3 text-left sm:mt-5">
              <div class="mt-2">
                <p class="block text-sm font-medium text-gray-500 pointer-events-none">Data: {{ new Date(image.updated_at * 1000).toLocaleDateString() }}</p>
                <p class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">Descrizione: {{ image.description }}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button @click.prevent="shareImage()" type="button" class="w-full inline-flex justify-center rounded-md border border border-gray-300 shadow-sm px-2.5 py-1.5 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">Condividi</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, defineProps, watch, inject } from 'vue'
import { XIcon } from '@heroicons/vue/solid'
import axios from "axios";

const props = defineProps(['status','image'])

// const open = ref(false)
const open = inject('modalVisibility')
const imageInfo = ref(false)

const closeModal = () => {
  open.value=false;
}

const shareImage = async () => {

  await axios.get('/api/image/'+imageInfo.value.id+'/share').then(response => {
    console.debug(response.data);
  })

}

onBeforeMount( () => {
  open.value = props.status;
  imageInfo.value = props.image;
});

watch(() => props.image, (selection, prevSelection) => {
  console.debug('Cambiamento di prop.status da: '+prevSelection+' a '+selection)
  // open.value = props.status;
  imageInfo.value = props.image;
})
</script>