<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1>Report attività coltura {{cropDetails.name}}</h1>
    <div class="">
      <div class="my-5">
        <img
            :src="cropDetails.ico_path"
            :alt="cropDetails.name"
            class="inline-block h-20 w-20 rounded-3xl m-auto border border-black shadow"
        />
      </div>

      <ul role="list" class="divide-y divide-gray-200">
        <li v-for="task in tasksList.data" :key="task.id" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <div class="flex justify-between space-x-3">
            <div class="min-w-0 flex-1">

              <span class="absolute inset-0" aria-hidden="true" />
              <p class="text-sm font-medium text-gray-900">Utente: {{ task.owner.name }}</p>
              <p class="text-sm text-gray-500">{{ task.label }}</p>

            </div>
            <time :datetime="task.datetime" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{{ new Date(task.scheduled_on * 1000).toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</time>
          </div>
          <div class="mt-1">
            <p class="text-sm text-gray-600">
              {{ task.description }}
            </p>
            <p>Completata {{new Date(task.completed_at * 1000).toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}}</p>
          </div>
        </li>

        <li v-if="tasksList.data.length === 0" class="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
          <div class="mt-1">
            <p class="text-sm text-gray-600">
              L'utente non ha completato task per questa coltura
            </p>
          </div>
        </li>

      </ul>


    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  useRoute
  // useRouter,
  // useRoute
} from 'vue-router'
import axios from 'axios'

const route = ref(useRoute())
const cropId = ref("");
const cropDetails = ref("");
const tasksList = ref({
  'data': [],
});

onMounted(() => {
  cropId.value = route.value.params.id

  axios.get('/api/crop/'+cropId.value).then(response => {
    cropDetails.value = response.data;
  })

  axios.get('/api/crop/'+cropId.value+'/report').then(response => {
    tasksList.value = response.data;
  })

})


</script>

<style scoped>

</style>