<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1 class="text-lg border-b-2 border-solid mb-5">Le stagioni</h1>

    <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      <li v-for="season in seasons" :key="season.id" class="relative">
        <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img :src="season.ico" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
          <button @click.prevent="router.push('/stagioni/id/'+season.id)" type="button" class="absolute inset-0 focus:outline-none">
            <span class="sr-only">Leggi gli articoli per la stagione</span>
          </button>
        </div>
        <p class="text-center">{{season.name}}</p>
      </li>
    </ul>

  </div>
</template>

<script setup>
import {ref, } from 'vue';
import { useRouter } from 'vue-router'

const router = ref(useRouter());

const seasons = ref([
  { 'id': 1, 'ico': '/images/seasons/winter.png', 'name': 'Inverno',},
  { 'id': 2, 'ico': '/images/seasons/springs.png', 'name': 'Primavera',},
  { 'id': 3, 'ico': '/images/seasons/summer.png', 'name': 'Estate',},
  { 'id': 4, 'ico': '/images/seasons/autumn.png', 'name': 'Autunno',},
]);
</script>
