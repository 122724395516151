<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1 v-if="route.query.globale === '1'" class="text-lg border-b-2 border-solid">Crea una nuova attività globale</h1>
    <h1 v-else-if="route.name === 'taskDuplicate'" class="text-lg border-b-2 border-solid">Duplica l'attività</h1>
    <h1 v-else class="text-lg border-b-2 border-solid">Crea una nuova attività</h1>
    <form v-if="task" class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Attività (obbligatoria)</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="task.label" type="text" name="username" id="username" autocomplete="username" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label for="about" class="block text-sm font-medium text-gray-700"> Descrizione </label>
              <div class="mt-1">
                <textarea v-model.lazy="task.description" id="description" name="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
              </div>
              <p class="mt-2 text-sm text-gray-500">Se vuoi, scrivi una breve descrizione</p>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label v-if="route.name === 'taskDuplicate'" for="scheduled_on" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Seleziona una data in cui ripetere l'attività (obbligatorio) </label>
              <label v-else for="scheduled_on" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Data attività (obbligatoria) </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model.lazy="task.scheduled_on" id="scheduled_on" name="scheduled_on" type="date" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

          </div>
        </div>

        <div>
          <label for="group" class="block text-sm font-medium text-gray-700">Coltura</label>
          <select
              v-model="cropSelected"
              id="group"
              name="group"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option
                v-for="crop in cropsList"
                :key="crop.id"
                :value="crop.id"
            >{{crop.name}}</option>
          </select>
        </div>

      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
              @click.prevent="cancelCreation"
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Annulla</button> &nbsp;
          <button
              @click.prevent="saveTask"
              type="submit"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Crea</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script setup>
import {user} from '@/store/userStore';
import {ref, onMounted, } from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';
import axios from 'axios'

const router = useRouter();
const route = useRoute();

const cropSelected = ref();
const cropsList = ref([])

const task = ref({
  'created_by':user.info.id,
  'user_id': user.info.id,
  'label': '',
  'description': '',
  'scheduled_on': '',
})
const requestParams = ref({})
const taskId = ref();

const saveTask = async () => {
  if (task.value.label !== '' && task.value.scheduled_on !== '') {
    if (cropSelected.value !== '') {
      task.value.crop_id = cropSelected.value;
    }
  //   await axios.post('/api/task', task.value)
  //       .then(() => {
  //         alert('Attività creata correttamente.');
  //         router.push('/todolist')
  //       })
  //       .catch(error => {
  //         console.debug(error.response)
  //       });
    await axios({
      method: 'post',
      url: '/api/task',
      data: task.value,
      params: requestParams.value,
    }).then(() => {
      alert('Attività creata correttamente.');
      router.push('/todolist')
    }).catch(error => {
      console.debug(error.response)
    });
  } else {
    alert('E\' necessario compilare i campi obbligatori')
  }
}

const cancelCreation = async () => {
  router.push('/todolist')
}

const loadCropsList = async () => {
  // await axios.get('/api/crop/all')
  await axios.get('/api/crop')
      .then(response => {
        // cropsList.value = response.data;
        cropsList.value = response.data.data;
      })
      .catch( error => {
        console.debug(error.response);
      })
}

const loadTask = async (id) => {
  await axios.get('/api/task/'+id)
      .then(response => {
        task.value = response.data;
        task.value.scheduled_on = new Date(task.value.scheduled_on * 1000).toISOString().split('T')[0];
      })
}

onMounted(async () => {
  if (route.query.globale === '1') {
    task.value.user_id = '';
    requestParams.value.all = '1';
  }

  if (route.name === 'taskDuplicate') {
    taskId.value = route.params.id;
    await loadTask(taskId.value);
    cropSelected.value = task.value.crop_id;
  }

  await loadCropsList();
})


</script>

<style scoped>

</style>