<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">

    <h1 class="text-lg border-b-2 border-solid mb-5">Archivio delle immagini pubblicate</h1>

    <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      <li v-for="image in imageList.data" :key="image.id" class="relative">
        <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img :src="$beDomain+image.path" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
          <button @click.prevent="openModal(image)" type="button" class="absolute inset-0 focus:outline-none">
            <span class="sr-only">View details for image</span>
          </button>
        </div>
      </li>
    </ul>

    <Paginator
        v-if="imageList.links !== []"
        :paginator="imageList"
        :route-id="'paginatedImagesList'"
    />
    <image-modal
        :image="imageToDisplay"
    />
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch, provide } from 'vue';
import {
  // useRouter,
  useRoute
} from 'vue-router';
import axios from 'axios';
import Paginator from '../../components/Paginator'
import ImageModal from '../../components/AdminImageModalPopup'

const route = useRoute()

const modalVisibility = ref(false)
provide('modalVisibility', modalVisibility)

const imageToDisplay = ref('')
const imageList = ref({
  'data': [],
  'links': [],
});

const loadImagesList = async (page = 1) => {
  if (parseInt(page) === 1) {
    await axios.get('/api/image')
        .then(response => {
          imageList.value = response.data;
        })
  } else {
    await axios.get('/api/image?page='+page)
        .then(response => {
          imageList.value = response.data;
        })
  }
}

const openModal = (imageUrl) => {
  modalVisibility.value = false;
  modalVisibility.value = true;
  imageToDisplay.value = imageUrl
}

onBeforeMount( async () => {
  await loadImagesList();
})

// fetch the user information when params change
watch(
    () => route.params.page,
    async () => {
      await loadImagesList(route.params.page)
    }
)

</script>