<template>
  <nav class="border-t border-gray-200 my-4 px-4 flex items-center justify-between sm:px-0">
    <div class="-mt-px w-0 flex-1 flex">
      <router-link
          v-if="paginator.prev_page_url"
          :to="{ name: props.routeId, params: { page: paginatorObject.current_page-1 }}"
          class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
      ><ArrowNarrowLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" /> Precedente</router-link>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
      <router-link
          v-for="item in paginatorObject.links"
          :key="item.label"
          :to="{ name: props.routeId, params: { page: item.label }}"
          :class="{'border-indigo-500 text-indigo-600': paginatorObject.current_page === parseInt(item.label)}"
          class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
          aria-current="page"
      > {{ item.label }} </router-link>

    </div>
    <div class="-mt-px w-0 flex-1 flex justify-end">
      <router-link
          v-if="paginator.next_page_url"
          :to="{ name: props.routeId, params: { page: paginatorObject.current_page+1 }}"
          class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
      >Successiva <ArrowNarrowRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" /></router-link>
    </div>
  </nav>
</template>

<script setup>
import {
  ref,
  watch,
  onBeforeMount,
  defineProps,
} from "vue";
import {
  useRoute,
  // useRouter
} from 'vue-router';
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/vue/solid';

const props = defineProps(['paginator', 'routeId'])
const paginatorObject = ref({
  'data': [],
  'links': [],
})


// const router = useRouter();
const route = ref(useRoute());

const page = ref('');

const removeFirstLastIndex = () => {
  paginatorObject.value.links.shift();
  paginatorObject.value.links.pop();
}

const updatePaginatorObject = () => {
  paginatorObject.value = props.paginator;
  removeFirstLastIndex();
}

onBeforeMount( () => {
  page.value = route.value.params.page;
  updatePaginatorObject();
});

watch(() => route.value.params.day, () => {
  if (route.value.name === 'todolist') {
    page.value = route.value.params.day;

  }
})

watch(() => props.paginator, () => {
    updatePaginatorObject();
})

</script>

<style scoped>

</style>