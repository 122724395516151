<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1 class="text-lg border-b-2 border-solid">Crea un nuovo utente</h1>
    <form v-if="userProfile" class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nome Utente *</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="userProfile.name" type="text" name="username" id="username" autocomplete="username" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Indirizzo e-mail *</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model.lazy="userProfile.email" id="email" name="email" type="email" autocomplete="email" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Password *</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                    v-model.lazy="userProfile.password"
                    id="password"
                    name="password"
                    type="password"
                    class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
              @click.prevent="cancelAction"
              type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Annulla</button>
          <button
              @click.prevent="saveUser"
              type="submit"
              class="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Crea</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script setup>
import {ref, onMounted, } from 'vue';
import {
  useRouter,
} from 'vue-router';
import axios from 'axios'

const router = useRouter();

const userProfile = ref({
  'name': '',
  'email': '',
  'password': '',
})

const saveUser = async () => {
  if (userProfile.value.name !== '' && userProfile.value.email !== '' && userProfile.value.password !== '') {
    await axios.post('/api/user', userProfile.value)
        .then(() => {
          alert('La creazione dell\'utente è andata a buon fine')
          router.push('/utenti/')
        })
        .catch(error => {
          console.debug(error.response)
        });
  } else {
    alert('E\' necessario compilare i campi obbligatori')
  }
}

const cancelAction = async () => {
  await router.push('/utenti/')
}

onMounted(async () => {

})


</script>

<style scoped>

</style>