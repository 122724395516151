<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-2xl p-2 border-black border-2">
    <h1>Modifica l'attività</h1>
    <form v-if="task" class="space-y-8 divide-y divide-gray-200 bg-white px-4 py-5">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>

          <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Attività</label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm">
                  <input v-model.lazy="task.label" type="text" name="username" id="username" autocomplete="username" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label for="about" class="block text-sm font-medium text-gray-700"> Descrizione </label>
              <div class="mt-1">
                <textarea v-model.lazy="task.description" id="description" name="description" rows="3" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md" />
              </div>
              <p class="mt-2 text-sm text-gray-500">Se vuoi, scrivi una breve descrizione</p>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="scheduled_on" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Data di nascita </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input v-model="task.scheduled_on" id="scheduled_on" name="scheduled_on" type="date" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
              @click.prevent="cancelCreation"
              type="button"
              class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >Annulla</button>
          <button
              @click.prevent="editTask"
              type="submit"
              class="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Salva</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script setup>
import {ref, onMounted, } from 'vue';
import {
    useRouter,
    useRoute,
} from 'vue-router';
import axios from 'axios'

const router = useRouter();
const route = ref(useRoute());

const taskId = ref();
const task = ref({
  'label': '',
  'description': '',
  'scheduled_on': '',
})

const editTask = async () => {
  if (task.value.label !== '' && task.value.scheduled_on !== '') {
    await axios.put('/api/task/'+taskId.value, task.value)
        .then(() => {
          alert("L'attività è stata modificata correttamente.")
          router.push('/todolist')
        })
        .catch(error => {
          console.debug(error.response)
        });
  } else {
    alert('E\' necessario compilare i campi obbligatori')
  }
}

const loadTask = async (id) => {
  await axios.get('/api/task/'+id)
      .then(response => {
        task.value = response.data;
        task.value.scheduled_on = new Date(task.value.scheduled_on * 1000).toISOString().split('T')[0];
      })
}

const cancelCreation = async () => {
  await router.push('/todolist')
}

onMounted(async () => {
  taskId.value = route.value.params.id;
  await loadTask(taskId.value);
})


</script>

<style scoped>

</style>